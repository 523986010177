import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Alert, Box, Container } from "@material-ui/core";

import Breadcrumbs from "../../components/Breadcrumbs";
import DispatchInvoicesForm from "../../components/invoice/dispatch/DispatchInvoicesForm";
import DispatchInvoicesRequestList from "../../components/invoice/dispatch/DispatchInvoicesRequestList";
import {
  executeDispatchInvoicesRequest,
  fetchDispatchInvoiceRequests,
} from "../../http/dispatchInvoicesRequest";
import AppCircularProgress from "../../components/AppCircularProgress";

const DispatchInvoices = () => {
  const [status, setStatus] = useState("stand-by");

  const dispatchInvoicesRequestQuery = useQuery({
    queryKey: ["dispatch_invoices_requests"],
    queryFn: () => fetchDispatchInvoiceRequests(),
  });

  const dispatchInvoicesRequest = useMemo(() => {
    if (dispatchInvoicesRequestQuery.isSuccess)
      return dispatchInvoicesRequestQuery.data["hydra:member"];

    return [];
  }, [dispatchInvoicesRequestQuery]);

  const handelExecute = (dispatchInvoicesRequest) => {
    setStatus("loading");
    executeDispatchInvoicesRequest(dispatchInvoicesRequest.id)
      .then(() => dispatchInvoicesRequestQuery.refetch())
      .then(() => setStatus("success"))
      .catch(() => setStatus("error"));
  };
  return (
    <Container maxWidth={false}>
      <Breadcrumbs />
      <Box sx={{ gap: "2rem", display: "flex", flexDirection: "column" }}>
        <DispatchInvoicesForm
          onValid={() => dispatchInvoicesRequestQuery.refetch()}
        />
        {status === "error" && (
          <Alert severity="error">Une erreur est survenue</Alert>
        )}
        {status === "success" && (
          <Alert severity="success">Les factures on bien été dispatcher</Alert>
        )}

        <DispatchInvoicesRequestList
          dispatchInvoicesRequest={dispatchInvoicesRequest}
          isLoading={dispatchInvoicesRequestQuery.isLoading}
          onExecute={handelExecute}
          inProgress={status === "loading"}
        />
        {status === "loading" && (
          <Box>
            <AppCircularProgress />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default DispatchInvoices;
