import React from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import { LoadingButton } from "@material-ui/lab";

const STATUS_MAPPING = {
  WAITING: "En attente",
  PROCESSING: "En cours",
  COMPLETED: "Complétée",
  FAILED: "Erreur",
  CANCELLED: "Annulée",
};

const DispatchInvoicesRequestList = ({
  dispatchInvoicesRequest,
  isLoading,
  onExecute,
  inProgress,
}) => {
  const columns = [
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
      valueGetter: ({ value }) => STATUS_MAPPING[value] ?? "Inconnue",
    },
    {
      field: "createdAt",
      headerName: "Date de création",
      flex: 1,
      valueGetter: ({ value }) => dayjs(value).format("DD/MM/YYYY"),
    },
    {
      field: "completedAt",
      headerName: "Date d'exécution",
      flex: 1,
      valueGetter: ({ value }) =>
        dayjs(value).isValid() ? dayjs(value).format("DD/MM/YYYY") : "",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) =>
        row.status === "WAITING" && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LoadingButton
              disabled={inProgress}
              onClick={() => onExecute(row)}
              variant="contained"
              color="primary"
            >
              éxecuter
            </LoadingButton>
          </Box>
        ),
    },
  ];

  return (
    <div>
      <Card>
        {isLoading || dispatchInvoicesRequest.length > 0 ? (
          <DataGrid
            columns={columns}
            rows={dispatchInvoicesRequest}
            rowCount={dispatchInvoicesRequest.length}
            loading={isLoading}
            pageSize={10}
            autoHeight
            disableColumnFilter
            disableSelectionOnClick
            disableColumnMenu
            disableColumnReorder
          />
        ) : (
          <Box sx={{ p: "2rem", textAlign: "center" }}>
            <Typography>Aucunes factures disponibles</Typography>
          </Box>
        )}
      </Card>
    </div>
  );
};

export default DispatchInvoicesRequestList;
