import http from "./http";

const openFile = async (apiUrl, filename = "", mime = "application/pdf") => {
  const { data, headers } = await http.get(apiUrl, {
    responseType: "blob",
  });

  const url = window.URL.createObjectURL(
    new Blob([data], { type: data.type ?? mime }),
  );
  const link = document.createElement("a");
  link.href = url;

  if (!filename) {
    const contentDisposition = headers.get("content-disposition");
    if (contentDisposition && contentDisposition.includes("filename="))
      filename = contentDisposition.split("filename=")[1].replace(/["']/g, "");
  }

  if (!filename) {
    link.setAttribute("target", "_blank");
  } else {
    link.setAttribute("download", filename);
  }

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export default openFile;
