import { LoadingButton } from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Download as DownloadIcon,
  HelpOutline,
  Search as SearchIcon,
} from "@material-ui/icons";
import { useMutation, useQuery } from "react-query";
import { connect } from "react-redux";
import FilterPanel from "../../components/FilterPanel";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import TrainingList from "../../components/training/TrainingList";
import TrainingToolbar from "../../components/training/TrainingToolbar";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";
import {
  exportTrainingHistoryExcel,
  retrieveTotalTrainingHistory,
} from "../../http/odysseal";

const Training = ({ cgpId, userId }) => {
  const actualYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(actualYear);
  const [filters, setFilters] = useState({ exercice: selectedYear });
  const rangeYears = 5;
  const years = Array(rangeYears)
    .fill("")
    .map((v, idx) => actualYear + idx - (rangeYears - 1));
  const agentId = cgpId;

  //récupère le nombre de formations qu'il y a sur l'année pour l'afficher à côté du tooltip
  const trainingCountQuery = useQuery({
    queryKey: ["odysseal", "training-count"],
    queryFn: async () => retrieveTotalTrainingHistory(),
  });

  const track = useTrackingContext();

  const onFilter = (filters) => {
    setFilters({ ...filters, exercice: selectedYear });
  };

  const onSubmit = () => {
    onFilter(filters);
    track.click(ACTION_CLICK.SET_FILTER);
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_MY_TRAINING);
    mutation.mutate(odyssealFilters);
  };

  const mutation = useMutation({
    mutationKey: ["odysseal", "export-training"],
    mutationFn: (filters) => exportTrainingHistoryExcel(filters),
  });

  const odyssealFilters = useMemo(() => {
    const model = [];
    if (filters.evenement) model.push(filters.evenement);
    if (filters.formation) model.push(filters.formation);

    return {
      year: filters.exercice,
      model: model,
      city: filters.ville,
      role: filters.statutParticipant,
      isValidated: filters.validationFormation
        ? eval(filters.validationFormation)
        : undefined,
    };
  }, [filters]);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Box sx={{ mt: 3, mb: 3 }}>
        <GuardedComponent componentName="training_export">
          <LoadingButton
            loading={mutation.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcel}
          >
            Exporter
          </LoadingButton>
        </GuardedComponent>
        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
          sx={{ marginLeft: "10px" }}
        >
          {years.map((year) => (
            <Button
              variant={selectedYear === year ? "contained" : "outlined"}
              key={year}
              onClick={() => setSelectedYear(year)}
            >
              {year}
            </Button>
          ))}
          <Button
            variant={!selectedYear ? "contained" : "outlined"}
            onClick={() => setSelectedYear()}
          >
            Tous
          </Button>
        </ButtonGroup>
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={onSubmit}
          sx={{ marginLeft: "10px" }}
        >
          Filtrer
        </Button>
      </Box>

      <FilterPanel sx={{ mt: 2 }}>
        <TrainingToolbar onFilter={onFilter} fromAgent />
      </FilterPanel>

      {trainingCountQuery.isSuccess && (
        <Box sx={{ mt: 3, mb: 3 }}>
          Total des formations validées sur l'exercice en cours :{" "}
          {trainingCountQuery.data.total} formations
          <Tooltip
            title={"Le nombre de formations est actualisé tous les mois"}
          >
            <HelpOutline sx={{ marginLeft: "2px", fontSize: "20px" }} />
          </Tooltip>
        </Box>
      )}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Card>
          <CardContent sx={{ p: 1 }}>
            Les formations sont les ateliers dispensés par le Trainer’s Club et
            sont comptabilisées dans vos 10 formations recommandées. Les
            évènements sont les trois évènements principaux (Convention,
            Journées Partenaires et Move’UP), ainsi que les Ateliers Partenaires
            et les Cercles. Un exercice s'étend du 1er Novembre N au 31 octobre
            N+1
          </CardContent>
        </Card>
      </Grid>
      <Box sx={{ mt: 3, mb: 3 }}></Box>
      <Card>
        <CardHeader
          title={
            <Typography color="white">Détail des formations : </Typography>
          }
          style={{ backgroundColor: "#00A19A" }}
        />
        <CardContent sx={{ p: 1 }}>
          <TrainingList filters={odyssealFilters} />
        </CardContent>
      </Card>
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cgpId: state.auth.cgpId,
  userId: state.auth.userId,
});

export default connect(mapStateToProps)(Training);
