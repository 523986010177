import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import {
  Alert,
  Box,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import * as yup from "yup";
import { postResetPassword } from "../../http/user";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'url("/static/background-login.jpg")',
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  box: {
    height: "100%",
    padding: theme.spacing(3),
    textAlign: "justify",
  },
  boxLight: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const ResetPasswordScreen = () => {
  const { token } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Ce champ est obligatoire.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{8,})/,
        "Ce champ doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre.",
      ),
    confirmPassword: yup
      .string()
      .required("Ce champ est obligatoire.")
      .oneOf([yup.ref("password"), null], "Le mot de passe doit correspondre."),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ password }) => {
    mutation.mutate({ password: password, token: token });
  };

  const mutation = useMutation(postResetPassword, {
    onSuccess: () => {
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    },
  });

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h1"
            className={classes.title}
          >
            Réinitialisation du mot de passe
          </Typography>
          <Grid container alignItems="stretch">
            <Grid item xs>
              <Box className={`${classes.box} ${classes.boxLight}`}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Typography component="div" variant="body1">
                    <p style={{ marginBottom: 10 }}>
                      Veuillez indiquer votre nouveau mot de passe. Celui-ci
                      doit :
                    </p>

                    <ul style={{ marginBottom: 10, paddingLeft: 25 }}>
                      <li>Être de 8 caractères minimum</li>
                      <li>Contenir au moins 1 caractère minuscule</li>
                      <li>Contenir au moins 1 caractère majuscule</li>
                      <li>Contenir au moins 1 chiffre</li>
                      <li>Contenir au moins 1 caractère spécial</li>
                    </ul>
                  </Typography>

                  {mutation.isError ? (
                    <Alert severity="error">
                      Une erreur est survenue, le lien est peut-être expiré,
                      veuillez recommencer la procèdure.
                    </Alert>
                  ) : null}

                  {mutation.isSuccess ? (
                    <Alert severity="success">
                      Votre mot de passe à bien été modifié. Vous allez être
                      redirigé sur la page de connexion.
                    </Alert>
                  ) : null}
                  <TextField
                    error={Boolean(errors.password?.message)}
                    fullWidth
                    helperText={errors.password?.message}
                    label="Mot de passe"
                    margin="normal"
                    type="password"
                    variant="outlined"
                    autoComplete="password"
                    autoFocus
                    defaultValue=""
                    {...register("password")}
                  />
                  <TextField
                    error={Boolean(errors.confirmPassword?.message)}
                    fullWidth
                    helperText={errors.confirmPassword?.message}
                    label="Confirmation du mot de passe"
                    margin="normal"
                    type="password"
                    variant="outlined"
                    autoComplete="password"
                    defaultValue=""
                    {...register("confirmPassword")}
                  />
                  <LoadingButton
                    loading={mutation.isLoading}
                    type="submit"
                    variant="contained"
                  >
                    Modifier
                  </LoadingButton>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ResetPasswordScreen;
