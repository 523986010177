import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import DocumentIcon from "../documentation/DocumentIcon";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { Download as DownloadIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  documentIcon: {
    fontSize: "26px",
  },
  pdfIcon: {
    color: "hsl(0, 100%, 50%)",
  },
  excelIcon: {
    color: "hsl(147, 99%, 27%)",
  },
  docIcon: {
    color: "hsl(221, 44%, 59%)",
  },
  documentRecentIcon: {
    color: "#8B0000",
  },
}));

const InvoicesLibrary = ({ files, onView, onDownload, withAgent }) => {
  const classes = useStyles();

  return (
    <div>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          padding: "0 0 16px 0",
        }}
      >
        <ListSubheader
          sx={{
            borderWidth: "0px 0px thin",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            paddingLeft: "36px",
            paddingRight: "36px",
            display: "flex",
            height: "55px",
            alignItems: "center",
            backgroundColor: "#00a19a",
            color: "#FFFFFF",
          }}
          key="header"
        >
          <ListItemText>Nom</ListItemText>
          {withAgent && <ListItemText>Agent</ListItemText>}
        </ListSubheader>
        {files.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              borderWidth: "0px 0px thin",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              paddingLeft: "17px",
              paddingRight: "17px",
            }}
          >
            <ListItemIcon sx={{ cursor: "pointer" }}>
              {file.type === "folder" ? (
                <FontAwesomeIcon
                  icon={faFolder}
                  className={classes.documentIcon}
                />
              ) : (
                <DocumentIcon
                  document={{ filePath: file.name }}
                  classes={classes}
                />
              )}
            </ListItemIcon>
            <ListItemText
              onClick={() => onView(file)}
              sx={{ cursor: "pointer", width: "12%" }}
              primary={file.name}
            />
            {withAgent && file.username && (
              <ListItemText
                onClick={() => onView(file)}
                sx={{ cursor: "pointer", width: "12%" }}
                primary={file.username}
              />
            )}
            {file.canDownload && (
              <ListItemIcon>
                <IconButton
                  onClick={() => onDownload(file)}
                  aria-label="Télécharger la facture"
                  size="small"
                >
                  <DownloadIcon />
                </IconButton>
              </ListItemIcon>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default InvoicesLibrary;
