import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  downloadMonthInvoices,
  retrieveAgentInvoices,
} from "../../http/invoices";
import React, { useMemo } from "react";
import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  Typography,
} from "@material-ui/core";
import InvoicesLibrary from "../../components/invoice/Invoicelibrary";
import { connect } from "react-redux";
import AppCircularProgress from "../../components/AppCircularProgress";

const AgentInvoices = ({ cgpId }) => {
  const invoicesQuery = useQuery({
    queryKey: ["agent-files", cgpId],
    queryFn: () => retrieveAgentInvoices(cgpId),
  });

  const { 0: path } = useParams();
  const [year, month] = (path ?? "").split("/");

  const location = useLocation();
  const history = useHistory();

  function extractFilename(filePath) {
    return filePath.split("/").at(-1);
  }

  const files = useMemo(() => {
    if (!invoicesQuery.isSuccess) return [];

    const invoices = invoicesQuery.data["hydra:member"];

    if (!year)
      return [...new Set(invoices.map((item) => item.year))].map((item) => ({
        type: "folder",
        name: item.toString(),
      }));

    if (!month)
      return [
        ...new Set(
          invoices
            .filter((item) => item.year === year)
            .map((item) => item.month),
        ),
      ]
        .sort((a, b) => a - b)
        .map((item) => ({
          type: "folder",
          name: item.toString(),
          canDownload: true,
        }));

    return invoices
      .filter((item) => item.year === year && item.month === month)
      .map((item) => ({
        type: "file",
        name: extractFilename(item.filename),
        canDownload: true,
        filename: item.filename,
      }))
      .sort((a, b) => a.name - b.name);
  }, [invoicesQuery]);

  const handleClick = (file) => {
    if (file.type === "folder") {
      history.push(`${location.pathname}/${file.name}`);
    } else {
      handleDownload(file);
    }
  };

  const handleDownload = (item) => {
    if (item.type === "file") {
      window.open(
        `${process.env.REACT_APP_API_URL}/media/invoices/${item.filename}`,
        "_blank",
      );
    } else if (item.canDownload) {
      void downloadMonthInvoices(year, item.name, cgpId);
    }
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs separator="/" sx={{ mb: "1rem" }}>
        <Link to="/my-invoices/library">Mes Factures</Link>
        {year && <Link to="/my-invoices/library/2024">{year}</Link>}
        {month && <Link to="/my-invoices/library/2024/12">{month}</Link>}
      </Breadcrumbs>
      <Card>
        {files.length > 0 ? (
          <InvoicesLibrary
            files={files}
            onView={handleClick}
            onDownload={handleDownload}
          />
        ) : invoicesQuery.isLoading ? (
          <AppCircularProgress />
        ) : (
          <Box sx={{ p: "2rem", textAlign: "center" }} component="div">
            <Typography>Aucunes factures disponibles</Typography>
          </Box>
        )}
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cgpId: state.auth.cgpId,
});

export default connect(mapStateToProps)(AgentInvoices);
