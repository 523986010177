import http from "../utils/http";
import openFolder from "../utils/downloadFolder";

export const retrieveInvoices = () => {
  return http.get("/invoices").then((resp) => resp.data);
};

export const retrieveAgentInvoices = (cgpId) => {
  return http
    .get(`/invoices?user.cgpId=${cgpId}`, {})
    .then((resp) => resp.data);
};

export const downloadMonthInvoices = (year, month, cgpId = null) => {
  const search = new URLSearchParams();

  search.set("year", year);
  search.set("month", month);

  if (cgpId) search.set("user.cgpId", cgpId);

  return openFolder(`/invoices/download?${search.toString()}`);
};
